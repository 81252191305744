import React from 'react';
import { Redirect } from 'react-router-dom';
import { createRoutes } from '../@crema/utility/Utils';
import { initialUrl } from '../shared/constants/AppConst';
import { brightcomDashboardPagesConfig } from './brightcom-dashboard';
import { configurationPagesConfig } from './configuration';
import { customerServiceConfig } from './customerservice';
import { deadletterPagesConfig } from './deadletter';
import { entityPagesConfig } from './entity';
import { errorPagesConfigs } from './errorPages';
import { externalItemsPagesConfig } from './externalitems';
import { externalMessagesPagesConfig } from './externalMessages';
import { jobStatusPagesConfig } from './jobstatus';
import { mappingPagesConfig } from './mapping';
import { authRouteConfig } from './msal-auth';
import { queuePagesConfig } from './queue';
import { retryQueuePagesConfig } from './retryqueue';
import { subtenantPagesConfig } from './subtenant';
import { tenantPagesConfig } from './tenant';
import { toolboxPagesConfig } from './toolbox';



const routeConfigs = [
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...tenantPagesConfig,
  ...brightcomDashboardPagesConfig,
  ...queuePagesConfig,
  ...retryQueuePagesConfig,
  ...deadletterPagesConfig,
  ...jobStatusPagesConfig,
  ...subtenantPagesConfig,
  ...mappingPagesConfig,
  ...configurationPagesConfig,
  ...externalItemsPagesConfig,
  ...externalMessagesPagesConfig,
  ...entityPagesConfig,
  ...toolboxPagesConfig,
  ...customerServiceConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
