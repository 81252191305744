import React from 'react';

export const customerServiceConfig = [
  {
    auth: ['admin'],
    routes: [
      {
        path:
          '/customerservices/customermanagement/viewpostedsalesinvoice/:salesInvoiceId',
        component: React.lazy(
          () => import('./Pages/ViewPostesSalesInvoice'),
        ),
      },
      {
        path:
          '/customerservices/customermanagement/viewallpostedsalesinvoices/:customerNo',
        component: React.lazy(
          () => import('./Pages/ViewCustomerPostedSalesInvoices'),
        ),
      },
      {
        path: '/customerservices/customermanagement/viewallorders/:customerId',
        component: React.lazy(
          () => import('./Pages/ViewCustomerOrdersDetails'),
        ),
      },
      {
        path: '/customerservices/customermanagement/viewallorders',
        component: React.lazy(() => import('./Pages/ViewCustomerOrders')),
      },
      {
        path: '/customerservices/customermanagement',
        component: React.lazy(() => import('./Pages/CustomerManagement')),
      },
      {
        path: '/customerservices',
        component: React.lazy(() => import('./Pages/CustomerServices')),
      },
    ],
  },
];
